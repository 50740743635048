<template>
  <!-- 画像アップロード用 -->
  <div
    class="drop-area bg-csub-light mb-3 py-3 px-3"
    v-on:dragleave.prevent
    v-on:dragover.prevent
    v-on:drop.prevent="onImageChange"
  >
    <span class="text-danger">{{ message }}</span>
    <b-button
      v-on:click="clickSelectBtn"
      variant="outline-csub"
      v-bind:disabled="disabled"
      class="mb-2"
      ><b>{{ buttonLabel }}<br />{{ text }}</b></b-button
    >
    <!-- 非表示input button click時にrefで参照 -->
    <input
      style="display: none"
      ref="input"
      type="file"
      v-on:change="onImageChange"
    />
    <b-img
      v-if="file && isImage"
      v-bind:src="file"
      alt="Upload image"
      class="upload-image"
      v-bind:thumbnail="thumbnail"
      v-bind="otherProps"
    />
    {{ fileName }}
  </div>
</template>

<script>
export default {
  props: {
    file: { type: String, required: false, default: null },
    fileName: { type: String, required: false, default: null },
    buttonLabel: {
      type: String,
      required: false,
      default: '写真撮影・写真の選択',
    },
    text: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    message: { type: String, required: false },
    otherProps: { type: Object, required: false, default: () => {} },
    thumbnail: { type: Boolean, required: false, default: true },
    isImage: { type: Boolean, required: false, default: true },
  },
  methods: {
    clickSelectBtn() {
      this.$refs.input.click()
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    onImageChange(event) {
      console.log('* 写真選択 *')
      console.log('event', event)
      const images = event.target.files
        ? event.target.files
        : event.dataTransfer.files
      const image = images[0]
      console.log('image', image, image.type)
      // jpeg or pngチェック
      if (this.isImage && image.type !== 'image/jpeg' && image.type !== 'image/png') {
        alert('jpeg or png形式のファイルを選択してください。')
        return
      }
      //パスをクリアしておかないと再度同じファイル選択時にonchangeが動かないため
      event.target.value = null

      this.getBase64(image)
        .then((result) => {
          this.$emit('upload-file', { name: image.name, image: result })
        })
        .catch((error) => alert('アップロードに失敗しました。' + error))
    },
  },
}
</script>

<style scoped>
.drop-area {
  min-height: 100px;
  border: 2px dotted #125294;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-break: break-all;
}
</style>
