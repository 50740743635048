<template>
  <!-- カタログ詳細 -->
  <b-container class="py-4">
    <h1 class="text-cmain lead">{{ workflowCode }} {{ name }}</h1>
    <p v-if="isLoading" class="text-center text-caccent">
      <b-icon icon="circle-fill" animation="throb"></b-icon>
      カタログ詳細情報の取得中です。
    </p>
    <p v-if="errMessage" class="text-center text-caccent">{{ errMessage }}</p>
    <section id="description" v-if="!isLoading && !errMessage">
      <b-card no-body class="border-0">
        <b-card-body>
          <b-card-title title-tag="h2" class="d-none">
            カタログ概要
          </b-card-title>
          <b-form-row no-gutters class="mb-3">
            <b-col lg="2" class="text-center align-self-center mb-3 mb-lg-0">
              <b-img
                v-if="imageUri"
                v-bind="imageProps"
                v-bind:src="imageUri"
                alt="Image"
              ></b-img>
              <b-img v-else v-bind="blankProps" alt="No Image"></b-img>
            </b-col>
            <b-col lg="10">
              <b-card-text>
                {{ description }}
              </b-card-text>
            </b-col>
          </b-form-row>
          <b-card-text> {{ wfpPeriod }} {{ wfpName }} </b-card-text>
          <b-card-text>
            {{ wfpDescription }}
          </b-card-text>
          <ul v-for="attachment in attachmentList" v-bind:key="attachment.id">
            <li>
              <a v-bind:href="attachment.fileUri">{{ attachment.fileName }}</a>
            </li>
          </ul>
          <b-card-text>
            {{ memo }}
          </b-card-text>
          <b-button
            variant="csub"
            v-on:click="
              showUpdateForm
                ? (showUpdateForm = false)
                : (showUpdateForm = true)
            "
          >
            {{
              showUpdateForm
                ? '登録/更新フォームを閉じる '
                : '追加項目登録/更新'
            }}
          </b-button>
        </b-card-body>
      </b-card>
    </section>
    <section id="add-info" v-if="showUpdateForm">
      <b-card no-body class="border-0">
        <b-card-body>
          <b-card-title title-tag="h2"> 追加情報登録/更新 </b-card-title>
          <b-card no-body>
            <b-card-body>
              <b-alert
                show
                v-if="messageAddInfo"
                v-bind:variant="alertColorAddInfo"
              >
                {{ messageAddInfo }}
              </b-alert>
              <b-card-text>
                <b-icon icon="info-circle-fill" variant="csub"></b-icon>
                イメージ画像は正方形の画像をご使用ください。
              </b-card-text>
              <b-form-row>
                <b-col lg="6">
                  <b-card-text class="mb-0"> イメージ画像 </b-card-text>
                  <image-uploader
                    v-bind:file="addItems.image ? addItems.image : imageUri"
                    v-on:upload-file="addItems.image = $event.image"
                    button-label="ファイル選択またはドラッグ&amp;ドロップ"
                    v-bind:other-props="imageProps"
                    v-bind:thumbnail="false"
                  />
                </b-col>
                <b-col lg="6">
                  <b-form-group label="備考" label-for="memo">
                    <b-form-textarea
                      id="memo"
                      v-model="addItems.memo"
                      rows="9"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
                  <b-button
                    variant="csub"
                    class="w-100"
                    v-on:click="updateAdditionalInfo()"
                  >
                    登録/更新
                  </b-button>
                </b-col>
              </b-form-row>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
    </section>
    <section id="add-attachment" v-if="showUpdateForm">
      <b-card no-body class="border-0">
        <b-card-body>
          <b-card-title title-tag="h2"> 添付ファイル登録/削除 </b-card-title>
          <b-card no-body>
            <b-card-body>
              <b-alert
                show
                v-if="messageAttachment"
                v-bind:variant="alertColorAttachment"
              >
                {{ messageAttachment }}
              </b-alert>
              <b-card-text class="mb-0"> 添付ファイル </b-card-text>
              <image-uploader
                v-bind:file="attachment"
                v-on:upload-file="
                  attachment = $event.image
                  attachmentName = $event.name
                "
                v-bind:file-name="attachmentName"
                button-label="ファイル選択またはドラッグ&amp;ドロップ"
                v-bind:isImage="false"
              />
              <b-form-row>
                <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
                  <b-button
                    variant="csub"
                    class="w-100 mb-3"
                    v-on:click="addAttachment()"
                  >
                    登録
                  </b-button>
                </b-col>
              </b-form-row>
              <b-table
                v-bind:fields="attachmentListFields"
                v-bind:items="attachmentList"
                responsive
              >
                <template v-slot:cell(delete)="row">
                  <b-button
                    variant="csub"
                    size="sm"
                    v-on:click="deleteAttachment(row.item.id)"
                  >
                    削除
                  </b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
    </section>
    <section id="data-list" v-if="!isLoading && !errMessage">
      <b-card no-body class="border-0">
        <b-card-body>
          <b-card-title title-tag="h2"> 蓄積可能なデータ </b-card-title>
          <b-card-text v-if="eventList.length == 0">
            蓄積可能なデータが登録されていません。
          </b-card-text>
          <b-card no-body class="border-0">
            <b-card-body v-for="event in eventList" v-bind:key="event.code">
              <b-card-title title-tag="h3" class="font-weight-bold">
                {{ event.code }} {{ event.name }}
              </b-card-title>
              <b-card-text>
                {{ event.description }}
              </b-card-text>
              <b-table
                v-bind:fields="thingListFields"
                v-bind:items="event.thingList"
                responsive
                bordered
                sticky-header="400px"
                head-variant="dark"
              >
                <template v-slot:cell(env)="row">
                  <div v-for="env in row.item.envList" v-bind:key="env.code">
                    {{ env.name }} /
                    {{ env.companyName ? env.companyName : '-' }} /
                    {{ env.manufacturingName ? env.manufacturingName : '-' }} /
                    {{ env.modelNumber ? env.modelNumber : '-' }}
                  </div>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
    </section>
  </b-container>
</template>

<script>
import ImageUploader from '@/components/common/ImageUploader'
export default {
  components: {
    ImageUploader,
  },
  props: ['workflowCode'],
  data() {
    return {
      name: null,
      description: null,
      imageUri: null,
      wfpName: null,
      wfpDescription: null,
      wfpPeriod: null,
      memo: null,
      attachmentList: [],
      eventList: [],
      imageProps: { width: 140, height: 140 },
      blankProps: {
        blank: true,
        blankColor: '#e7e7e7',
        width: 140,
        height: 140,
      },
      thingListFields: [
        { key: 'code', label: '', tdClass: 'text-center' },
        { key: 'name', label: 'モノ名称', tdClass: 'cell-break-small' },
        { key: 'description', label: '概要', tdClass: 'cell-break' },
        { key: 'categoryName', label: 'カテゴリ' },
        { key: 'dataAmount', label: '蓄積量', tdClass: 'text-right' },
        { key: 'numOfPeople', label: '蓄積人数', tdClass: 'text-right' },
        { key: 'consideration', label: '対価', tdClass: 'text-right' },
        { key: 'env', label: 'モノ環境' },
      ],
      isLoading: true,
      errMessage: null,
      showUpdateForm: false,
      addItems: {
        image: null,
        memo: null,
      },
      messageAddInfo: null,
      alertColorAddInfo: null,
      attachmentListFields: [
        { key: 'fileName', label: '' },
        { key: 'delete', label: '', tdClass: 'text-center' },
      ],
      attachment: null,
      attachmentName: null,
      messageAttachment: null,
      alertColorAttachment: null,
    }
  },
  created() {
    this.getWorkflow()
  },
  methods: {
    getWorkflow() {
      // ワークフロー詳細取得
      console.log('** get workflow **')
      this.errMessage = null
      this.isLoading = true
      this.$axiosToken
        .get('/catalog/workflows/' + this.workflowCode)
        .then((response) => {
          console.log('** workflow detail **', response)
          this.name = response.data.name
          this.description = response.data.description
          if (response.data.imageUri) {
            this.imageUri = response.data.imageUri + '?' + new Date().getTime()
          }
          this.wfpName = response.data.wfpName
          this.wfpDescription = response.data.wfpDescription
          this.wfpPeriod = response.data.wfpPeriod
          this.memo = response.data.memo
          this.attachmentList = response.data.attachmentList
          this.eventList = response.data.eventList
          this.addItems.memo = this.memo
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            this.errMessage = 'エラー: ' + error.response.data.detail
          } else {
            this.errMessage = 'エラー: カタログ詳細情報の取得に失敗しました。'
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    removeDataUrlHeader(file) {
      // 送信用に画像データ先頭のdata:image/jpeg;base64,などを削除する
      if (file) {
        return file.replace(/^.*,/, '')
      } else {
        return null
      }
    },
    updateAdditionalInfo() {
      // 追加情報登録更新
      console.log('** update info **')
      this.$axiosToken
        .post('/catalog/workflows/' + this.workflowCode + '/add_info', {
          image: this.removeDataUrlHeader(this.addItems.image),
          memo: this.addItems.memo,
        })
        .then((response) => {
          console.log(response)
          if (response.data.imageUri) {
            this.imageUri = response.data.imageUri + '?' + new Date().getTime()
          }
          this.memo = response.data.memo
          this.addItems.image = null
          this.addItems.memo = this.memo
          this.alertColorAddInfo = 'csub'
          this.messageAddInfo = '追加情報の登録/更新が完了しました。'
        })
        .catch((error) => {
          console.log(error)
          this.alertColorAddInfo = 'caccent'
          if (error.response) {
            console.log(error.response)
            this.messageAddInfo = 'エラー: ' + error.response.data.detail
          } else {
            this.messageAddInfo = 'エラー: 追加情報の登録/更新に失敗しました。'
          }
        })
    },
    getAttachmentList() {
      // 添付ファイル取得
      console.log('** get attachment **')
      this.$axiosToken
        .get('/catalog/workflows/' + this.workflowCode + '/attachments')
        .then((response) => {
          console.log(response)
          this.attachmentList = response.data.attachmentList
        })
        .catch((error) => {
          console.log(error)
          alert('エラー: 添付ファイル一覧の再取得に失敗しました。')
        })
    },
    deleteAttachment(attachmentId) {
      // 添付ファイル削除
      console.log('** delete attachment **', attachmentId)
      this.$axiosToken
        .delete('/catalog/attachments/' + attachmentId)
        .then((response) => {
          console.log(response)
          alert('削除されました。')
          // 一覧更新
          this.getAttachmentList()
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert('エラー: ' + error.response.data.detail)
          } else {
            alert('エラー: 添付ファイルの削除に失敗しました。')
          }
        })
    },
    addAttachment() {
      // 添付ファイル登録
      console.log('** add attachment **')
      if (!this.attachment) {
        alert('添付ファイルを選択してください。')
        return
      }
      this.$axiosToken
        .post('/catalog/workflows/' + this.workflowCode + '/attachments', {
          file: this.removeDataUrlHeader(this.attachment),
          fileName: this.attachmentName,
        })
        .then((response) => {
          console.log(response)
          this.alertColorAttachment = 'csub'
          this.messageAttachment =
            '添付ファイルの登録が完了しました。' + this.attachmentName
          this.attachment = null
          this.attachmentName = null
          // 一覧更新
          this.getAttachmentList()
        })
        .catch((error) => {
          console.log(error)
          this.alertColorAttachment = 'caccent'
          if (error.response) {
            console.log(error.response)
            this.messageAttachment = 'エラー: ' + error.response.data.detail
          } else {
            this.messageAttachment =
              'エラー: 添付ファイルの登録に失敗しました。'
          }
        })
    },
  },
}
</script>
